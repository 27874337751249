/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  ///////////////
  // Functions //
  ///////////////

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        if ( $('.simple-text-read-more-button').length ) {
          $('.simple-text-read-more-button').on('click',function(e){
            e.preventDefault();
            $(this).parent().prev().addClass('active');
            $(this).remove();
          });
        }

        if ( $('.main-nav-list-item-link').length ) {
          $('.main-nav-list-item-link').each(function(i,e) {
            var currentUrl = window.location.href.split(window.location.protocol)[1];

            if ( $(this).attr('href') == currentUrl ) {
              $(this).addClass('active');
            }
          });
        }

        // Add the Our Team JS action
        if ( $('.our-team-column--main').length ) {
          $('.our-team-column--main').on('click',function(e) {
            e.preventDefault();
            var $this = $(this);
            var bio = $this.attr('data-bio');
            var title = $this.attr('data-title');
            var name = $this.attr('data-name');
            var emailAddress = $this.attr('data-email-address');
            var linkedInProfileLink = $this.attr('data-linkedin-profile-link');
            var certifications = $this.attr('data-certifications');
            var practiceAreas = atob( $this.attr('data-practice-areas') );
            var education = atob( $this.attr('data-education') );
            var designations = atob( $this.attr('data-designations') );
            var community = atob( $this.attr('data-community') );
            var picture = $this.attr('data-picture');
            var stats = '';


            $('.our-team-row--main').animate({'opacity':0},250,function(){
              $('.our-team-row--main').hide();
              var activePanel = '<div class="our-team-row--active" id="active-panel">'+
                                  '<a href="#" class="our-team-close-button" id="active-panel-close-button"><i class="fa fa-close"></i></a>'+
                                  '<div class="our-team-column--active-left">'+
                                    '<img class="our-team-picture--active" id="active-picture" src="" alt="">'+
                                    '<h3 class="our-team-description-name--active" id="active-name"></h3>'+
                                    '<p class="our-team-description-title--active" id="active-title"></p>'+
                                    '<a class="our-team-description-email-address--active" id="active-email-address" href=""><i class="fa fa-envelope"></i></a>'+
                                    '<a class="our-team-description-linkedin-profile-link--active" id="active-linkedin-link" href="" target="_blank"><i class="fa fa-linkedin"></i></a>'+
                                  '</div>'+
                                  '<div class="our-team-column--active-middle">'+
                                    '<div class="our-team-description-bio--active" id="active-bio"></div>'+
                                    '<div id="our-team-profile-stats-mobile" class="our-team-column--active-mobile">'+
                                    '</div>'+
                                  '</div>'+
                                  '<div id="our-team-profile-stats" class="our-team-column--active-right">'+
                                  '</div>'+
                                '</div>';

              $('.our-team-container').append(activePanel);

              // find the active panel and insert person info
              $('#active-picture').attr('src',picture);
              $('#active-picture').attr('alt','Photo of '+name);
              $('#active-name').html(name+', '+certifications);
              $('#active-title').html(title);
              $('#active-bio').html(bio);
              $('#active-email-address').attr('href','mailto:'+emailAddress);
              $('#active-linkedin-link').attr('href',linkedInProfileLink);
              if (practiceAreas) {
                stats += '<h4 class="our-team-description-stat-title">Practice Areas</h4><ul class="our-team-description-stat-list">'+practiceAreas+'</ul>';
              }
              if (education) {
                stats += '<h4 class="our-team-description-stat-title">Education</h4><ul class="our-team-description-stat-list">'+education+'</ul>';
              }
              if (designations && emailAddress !== 'mary@verdiwealthplanning.com' && emailAddress !== 'mark@verdiwealthplanning.com') {
                stats += '<h4 class="our-team-description-stat-title">Designations/Registrations</h4><ul class="our-team-description-stat-list">'+designations+'</ul>';
              } else if (designations) {
                stats += '<h4 class="our-team-description-stat-title">Registrations</h4><ul class="our-team-description-stat-list">'+designations+'</ul>';
              }
              if (community) {
                stats += '<h4 class="our-team-description-stat-title">Community</h4><ul class="our-team-description-stat-list">'+community+'</ul>';
              }
              $('#our-team-profile-stats').append(stats);
              $('#our-team-profile-stats-mobile').append(stats);

              // animate the active panel in
              $('#active-panel').animate({'opacity':1},250);
            });
          });
        }

        // add delegated click handler for active panel close button
        if ( $('.our-team-container').length ) {
          $('.our-team-container').on('click', '#active-panel-close-button', function(e) {
            e.preventDefault();
            $('#active-panel').animate({'opacity':0},250,function() {
              $('#active-panel').remove();
              $('.our-team-row--main').show();
              $('.our-team-row--main').animate({'opacity':1},250);
            });
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        windowWidth = $(window).innerWidth();

        // remove the Read More button from the first simple text section
        if ( $('.simple-text').length ) {
          $('.simple-text:nth-of-type(1)').find('.simple-text-read-more-button-container').remove();
        }

        // Slick slider for the Blog post section
        if ( $('#our-blog-section-links').length && windowWidth >= 767 ) {
          $('#our-blog-section-links').slick({
            'slidesToShow': 4,
            'prevArrow': '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"><i class="fa fa-angle-left"></i></button>',
            'nextArrow': '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"><i class="fa fa-angle-right"></i></button>',
            'responsive': [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          });
        }

        // Slick slider for the Blog post section
        if ( $('#our-services-carousel').length && windowWidth >= 767 ) {
          $('#our-services-carousel').slick({
            'slidesToShow': 4,
            'prevArrow': '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"><i class="fa fa-angle-left"></i></button>',
            'nextArrow': '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"><i class="fa fa-angle-right"></i></button>',
            'responsive': [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          });
        }

        function killSlickBlog() {
          if ( $(window).innerWidth() != windowWidth ) {
            windowWidth = $(window).innerWidth();
            if ( windowWidth < 767 ) {
              if ( $('#our-blog-section-links').hasClass('slick-initialized') ) {
                $('#our-blog-section-links').slick('unslick');
              }
            } else if ( !$('#our-blog-section-links').hasClass('slick-initialized') ) {
              $('#our-blog-section-links').slick({
                'slidesToShow': 4,
                'prevArrow': '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"><i class="fa fa-angle-left"></i></button>',
                'nextArrow': '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"><i class="fa fa-angle-right"></i></button>',
                'responsive': [
                  {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 3
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 2
                    }
                  }
                ]
              });
            }
          }
        }
        var killSlickBlogDebounced = debounce(killSlickBlog, 200, false);

        function killSlickServices() {
          if ( $(window).innerWidth() != windowWidth ) {
            windowWidth = $(window).innerWidth();
            if ( windowWidth < 767 ) {
              if ( $('#our-services-carousel').hasClass('slick-initialized') ) {
                $('#our-services-carousel').slick('unslick');
              }
            } else if ( !$('#our-services-carousel').hasClass('slick-initialized') ) {
              $('#our-services-carousel').slick({
                'slidesToShow': 4,
                'prevArrow': '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"><i class="fa fa-angle-left"></i></button>',
                'nextArrow': '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"><i class="fa fa-angle-right"></i></button>',
                'responsive': [
                  {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 3
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 2
                    }
                  }
                ]
              });
            }
          }

        }
        var killSlickServicesDebounced = debounce(killSlickServices, 200, false);
        if ( $('#our-blog-section-links').length ) {
          $(window).resize(killSlickBlogDebounced);
        }

        if ( $('#our-services-carousel').length ) {
          $(window).resize(killSlickServicesDebounced);
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Services page
    'services': {
      init: function() {
        // JavaScript to be fired on the Services page

        // make sure the anchors trigger the sections to expand
        if (window.location.hash) {
          var hash = window.location.hash.substring(1); // puts hash in variable, and removes the # character
          var $container = $('#'+hash).closest('.simple-text-inner-wrapper');
          $container.addClass('active');
          $container.next('.simple-text-read-more-button-container').remove();
        }
      }
    },
    // Resources page
    'resources': {
      init: function() {
        // JavaScript to be fired on the resources page

        // Set up the Load More link for the weekly market updates
        function loadMoreMarketUpdates (e) {
          e.preventDefault();

          var $promise = $.ajax({
            'url': mainjs.ajaxUrl,
            'method': 'POST',
            'dataType': 'json',
            'data': {'action': 'loadMoreMarketUpdates', 'security': mainjs.nonce}
          });

          $promise.done(function(response) {
            var html = '';
            response.forEach(function(e,i){
              html += '<p class="weekly-market-update-paragraph"><a href="'+e.link+'">'+e.title+'</a></p>';
            });

            $('.weekly-market-update-paragraph--load-more').before(html);

            if ( response.length < 5 ) {
              $('.weekly-market-update-paragraph--load-more').remove();
            }
          });
        }

        $('#loadMoreMarketUpdates').on('click', loadMoreMarketUpdates);



        //////////////////////////////////
        // FAQ section accordion toggle //
        //////////////////////////////////
        if ( document.getElementsByClassName("faq-accordion") ) {
          var acc = document.getElementsByClassName("faq-accordion");
          var i;

          for (i = 0; i < acc.length; i++) {
            acc[i].onclick = function() {
              this.classList.toggle("active");
              var panel = this.nextElementSibling;
              if (panel.style.maxHeight){
                panel.style.paddingTop = "0";
                panel.style.paddingBottom = "0";
                panel.style.maxHeight = null;
              } else {
                panel.style.paddingTop = "20px";
                panel.style.paddingBottom = "20px";
                panel.style.maxHeight = panel.scrollHeight+40 + "px";
              }
            };
          }
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'blog': {
      init: function() {
        // JavaScript to be fired on the blog page

        // Event handler function for Older Posts button click
        function loadPosts(e) {
          e.preventDefault();

          // make AJAX request
          var $promise = $.ajax({
            'url': mainjs.ajaxUrl,
            'method': 'POST',
            'dataType': 'json',
            'data': {'action': 'loadMorePosts', 'security': mainjs.nonce}
          });

          $promise.done(function(response){
            var blogPosts = '';
            var playButton = '';
            response.forEach(function(e,i){
              if ( e.postType == 'video' ) {
                playButton = '<span class="blog-gallery-play-button"><i class="fa fa-play"></i></span>';
              }
              blogPosts += '<div class="blog-gallery-column"><a href="'+e.permalink+'" class="blog-gallery-column--child" style="background-image:url('+e.mastheadImage+');">'+
                            '<h2 class="blog-gallery-post-title">'+e.title+'</h2>'+playButton+
                          '</a></div>';
            });

            if (response.length < 5) {
              // remove load more button
              $('#loadPosts').remove();
            }

            $('.blog-gallery-row:first-child').append(blogPosts);
          });
        }

        $('#loadPosts').on('click',loadPosts);
      },
      finalize: function() {
        // JavaScript to be fired on the blog page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
